import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { FollowerData } from "~/utils/types/FollowerData";
import { cache } from "~/utils/cache";
import type {
  AuthorPerm,
  HiveContent,
  ParsedAccount,
  PossibleHiveContent
} from "~/utils/hive";
import {
  fetchAccountFollowers,
  fetchCommunities,
  fetchFollowingPosts,
  fetchHiveTrendingPosts,
  queryAccountLookup
} from "~/utils/hive";
import type { checkFollowing, PossibleScotContent } from "~/utils/scot";
import { fetchFollowers, fetchFollowing } from "~/utils/scot";
import type { FeaturedThreadTags } from "~/utils/thread";
import { saveInfraDraft, saveInfraSchedule } from "~/utils/infra";
import { getCookie } from "~/utils/cookie";
import type { CachedDiscussion } from "~/utils/leocache";
import { leocache } from "~/utils/leocache";
import { toast } from "react-toastify";
import areLengthsSame from "~/utils/single/areLengthsSame";
import type { Option } from "~/components/publish/types";

export enum ScotFetchState {
  Initial,
  Failed,
  Fetched
}

export function useScotContent(authorPerm: AuthorPerm, active_votes) {
  const [fetchState, setFetchState] = useState(ScotFetchState.Initial);
  const [scotContent, setScotContent] = useState<PossibleScotContent>({
    pending_token: 0,
    total_payout_value: 0,
    active_votes: []
  });

  const fetchScottContent = useCallback(async () => {
    try {
      const _scotContent = await cache.getScotContent(authorPerm);
      if (
        areLengthsSame(
          typeof active_votes !== "undefined"
            ? active_votes
            : scotContent?.active_votes,
          _scotContent?.active_votes
        )
      ) {
        return;
      }
      setScotContent(_scotContent);
      setFetchState(ScotFetchState.Fetched);
    } catch (_) {
      setFetchState(ScotFetchState.Failed);
    }
  }, [authorPerm, active_votes, scotContent, setScotContent, setFetchState]);

  useEffect(() => {
    void (async function () {
      try {
        await fetchScottContent();
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, [authorPerm, setScotContent, setFetchState]);

  useEffect(() => {
    if (fetchState == ScotFetchState.Failed) {
      fetchScottContent();
    }
  }, [fetchState]);

  return [fetchState, scotContent, setScotContent, fetchScottContent] as const;
}

// why seperate states?
export enum HiveFetchState {
  Initial,
  Failed,
  Fetched
}

export function useHiveAccount(author: string) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [hiveContent, setHiveContent] = useState<ParsedAccount>();

  useEffect(() => {
    (async () => {
      try {
        const hiveContent = await cache.getAccount(author);
        setHiveContent(hiveContent);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [author, setFetchState, setHiveContent]);

  return [fetchState, hiveContent] as const;
}

export function useHiveReplies(authorPerm: AuthorPerm) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [accounts, setAccounts] = useState<any>();
  const [replies, setReplies] = useState<PossibleHiveContent>(null);

  useEffect(() => {
    (async () => {
      try {
        const fetchedReplies = await cache.getReplies(authorPerm);
        const splittedAccounts = (fetchedReplies as any).map(
          (reply: any) => reply.author
        );
        const fetchedAccounts = await cache.getAccounts(splittedAccounts);

        setReplies(fetchedReplies);
        setAccounts(fetchedAccounts);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [authorPerm, setFetchState, setAccounts, setReplies]);

  return [accounts, replies, fetchState] as const;
}

export function useHiveContent(AuthorPerm: AuthorPerm, disabled?: boolean) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [hiveContent, setHiveContent] = useState<HiveContent>();

  useEffect(() => {
    if (disabled) return;

    (async () => {
      try {
        const hiveContent = await cache.getContent(AuthorPerm);
        setHiveContent(hiveContent);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [setFetchState, setHiveContent, disabled]);

  return [fetchState, hiveContent] as const;
}

interface useHiveContentFetcher {
  tag: string;
  sort: string;
  limit: number;
  start_author?: keyof AuthorPerm["author"] | null;
  start_permlink?: keyof AuthorPerm["permlink"] | null;
  observer: string | null; // Name
}

export function useHiveContentFetcher(
  params: useHiveContentFetcher,
  replace: boolean = false,
  initialData: any[] = [],
  fetchingEnabled: boolean = true
): [any[], () => void, boolean] {
  const [fetchedContent, setFetchedContent] = useState<any[]>(initialData);
  const [lastParams, setLastParams] = useState<useHiveContentFetcher | null>(
    null
  );

  const [fetching, setFetching] = useState(false);

  // Handle parameter changes
  useEffect(() => {
    if (!lastParams) {
      setLastParams(params);
    } else {
      if (params.sort !== lastParams.sort || params.tag !== lastParams.tag) {
        setFetchedContent([]);
        setLastParams(params);
      }
    }
  }, [params.sort, params.tag, replace, params, lastParams]);

  const fetcherCallback = useCallback(async () => {
    setFetching(true);
    const lastPost = fetchedContent?.at(-1);
    const content = await fetchHiveTrendingPosts({
      ...params,
      start_author: lastPost ? lastPost.author : params.start_author,
      start_permlink: lastPost ? lastPost.permlink : params.start_permlink
    });
    setFetchedContent(previousContent => [
      ...previousContent,
      ...(content || [])
    ]);
    setFetching(false);
  }, [fetchedContent, params]);

  // Fetch data only if fetching is enabled and no data is present
  useEffect(() => {
    if (fetchingEnabled && fetchedContent.length === 0) {
      fetcherCallback();
    }
  }, [fetchedContent, fetchingEnabled, fetcherCallback]);

  return [fetchedContent, fetcherCallback, fetching];
}
// export function useHiveCommunityMembersFetcher(community: string) {
//   const [fetchedContent, setFetchedContent] = useState([]);

//   useEffect(() => {
//     if (fetchedContent.length === 0) {
//       fetcherCallback();
//     }
//   }, []);

//   const fetcherCallback = useCallback(async () => {
//     const content = await fetchHiveTrendingPosts({
//       community
//     });

//     //@ts-ignore
//     setFetchedContent(previousContent => [...previousContent, ...content]);
//   }, [community]);

//   return [fetchedContent, fetcherCallback];
// }

interface useFollowingContentFetcher {
  author: string;
  limit: number;
  sort: "feed";
  start_author: string | null;
  start_permlink: string | null;
  observer: string | null;
}

export function useFollowingContentFetcher(
  params: useFollowingContentFetcher,
  replace: boolean = false
) {
  const [fetchedContent, setFetchedContent] = useState([]);

  useEffect(() => {
    if (fetchedContent.length === 0) {
      fetcherCallback();
    }
  }, []);

  const excludeAuthorAndPermlinkFromParams = useMemo(() => {
    return Object.keys(params).reduce((acc, key) => {
      if (key === "start_author" || key === "start_permlink") {
        return acc;
      }

      return {
        ...acc,
        [key]: params[key]
      };
    }, {});
  }, [params]);

  // useEffect(() => {
  //   replace && setFetchedContent([]);
  //   fetcherCallback();
  // }, [excludeAuthorAndPermlinkFromParams, replace]);

  useEffect(() => {
    fetcherCallback();
  }, [params.start_author, params.start_permlink]);

  const fetcherCallback = useCallback(async () => {
    const content = await fetchFollowingPosts({
      ...params,
      ...(fetchedContent.length > 0 && {
        start_author: fetchedContent?.at(-1)?.author,
        start_permlink: fetchedContent?.at(-1)?.permlink
      })
    });

    const _content = content || [];

    //@ts-ignore
    setFetchedContent(previousContent => [...previousContent, ..._content]);
  }, [fetchedContent, params]);

  return [fetchedContent, fetcherCallback];
}

enum CommunitiesSorting {
  RANK = "rank",
  NEW = "new",
  MEMBERS = "subs"
}

interface useCommunitiesFetcher {
  limit: number;
  sort: CommunitiesSorting;
  query: string | null;
  last: string;
  observer: string | null;
}

export function useCommunitiesFetcher(
  params: useCommunitiesFetcher,
  replace: boolean = false,
  isDefaultSettings: boolean = true
) {
  const [fetchedContent, setFetchedContent] = useState([]);
  const lastSorting = useRef<string>("");
  const lastQuery = useRef<string | null>(null);

  useEffect(() => {
    fetcherCallback();
  }, [params.sort, params.query]);

  const fetcherCallback = useCallback(async () => {
    if (params.query && lastQuery.current === params.query) {
      return;
    }

    const content = await fetchCommunities({
      ...params,
      ...((!params.query || isDefaultSettings) &&
        fetchedContent.length > 0 && {
          last: fetchedContent?.at(-1).name
        })
    });

    //@ts-ignore
    setFetchedContent(previousContent => {
      if (
        !params.query &&
        lastSorting.current === params.sort &&
        lastQuery.current === params.query
      ) {
        return [...previousContent, ...(content || [])];
      } else {
        return content;
      }
    });

    lastSorting.current = params.sort;
    lastQuery.current = params.query;
  }, [fetchedContent, params, isDefaultSettings]);

  return [fetchedContent, fetcherCallback];
}

export function useScotFollowing({
  follower,
  account,
  enabled
}: checkFollowing) {
  const [followerList, setFollowerList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    if (!enabled || !account || !follower) return;
    void (async function () {
      try {
        const _followerList = await fetchFollowing({ follower, account });
        setFollowerList(_followerList as any);
      } catch {
        setFollowerList([]);
      }
    })();
  }, [follower, account, enabled]);

  useEffect(() => {
    if (!followerList) return;
    const isFollowingBool =
      followerList?.filter((data: FollowerData) => data.following === account)
        ?.length === 1;
    setIsFollowing(isFollowingBool);
  }, [account, followerList]);

  const checkIsFollowing = (accountToBeChecked: string) => {
    if (!followerList) return;
    const isFollowingBool =
      followerList?.filter(
        (data: FollowerData) => data.following === accountToBeChecked
      )?.length === 1;
    return isFollowingBool;
  };

  return {
    followerList,
    isFollowing,
    setFollowerList,
    setIsFollowing,
    checkIsFollowing
  };
}

export function useHiveFollowers(accountName: string) {
  const [fetchedContent, setFetchedContent] = useState([]);
  const [allLoaded, setAllLoaded] = useState(false);
  const [loadingParams, setLoadingParams] = useState(() => 0);

  const fetcherCallback = useCallback(async () => {
    const content = await fetchAccountFollowers(accountName, loadingParams);
    if (content.length < 200) setAllLoaded(true);
    //@ts-ignore
    setFetchedContent(previousContent => [
      ...previousContent,
      ...(content || [])
    ]);
  }, [accountName]);

  const fetcherContinue = () => {
    if (allLoaded) return false;
    return setLoadingParams(fetchedContent.length);
  };

  useEffect(
    () =>
      void (async function () {
        fetcherCallback();
      })(),
    [loadingParams]
  );

  fetchedContent.length === 0 && fetcherCallback();

  return [fetchedContent, fetcherContinue];
}

export function useScotAccount(accountName: string) {
  const [scotAccount, setScotAccount] = useState();

  useEffect(() => {
    void (async function () {
      try {
        const scotAccountData = await fetchScotAccount(accountName);
        setScotAccount(scotAccountData);
      } catch {
        setScotAccount(null);
      }
    })();
  }, [accountName]);

  return { scotAccount, setScotAccount };
}

export function useScotFollower({ follower, account }: checkFollowing) {
  const [followerList, setFollowerList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    void (async function () {
      try {
        const _followerList = await fetchFollowers({ follower, account });
        setFollowerList(_followerList as any);
      } catch {
        setFollowerList([]);
      }
    })();
  }, [follower]);

  useEffect(() => {
    if (!followerList) return;
    const isFollowingBool =
      followerList?.filter((data: FollowerData) => data.following === account)
        ?.length === 1;
    setIsFollowing(isFollowingBool);
  }, [account, followerList]);

  return { followerList, isFollowing, setFollowerList, setIsFollowing };
}

export function useThreadsSearch(query: string) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [cacheContent, setCacheContent] = useState<CachedDiscussion[] | []>([]);

  useEffect(() => {
    (async () => {
      try {
        const hiveContent = await leocache.genericSearch(query);
        if (!areLengthsSame(hiveContent, cacheContent)) {
          setCacheContent(hiveContent);
          setFetchState(HiveFetchState.Fetched);
        }
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [query, setFetchState, setCacheContent]);

  return [fetchState, cacheContent] as const;
}

export function useThreadsAccountSearch(author: string, query: string) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [cacheContent, setCacheContent] = useState<CachedDiscussion[] | []>([]);

  useEffect(() => {
    (async () => {
      try {
        const hiveContent = await leocache.authorSearch(author, query);
        setCacheContent(hiveContent);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [query, setFetchState, setCacheContent]);

  return [fetchState, cacheContent] as const;
}

export function useHiveAccountSearch(query: string) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [hiveContent, setHiveContent] = useState<HiveContent[] | string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const _hiveContent = await queryAccountLookup(query);
        setHiveContent(_hiveContent);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [query, setFetchState, setHiveContent]);

  return [fetchState, hiveContent] as const;
}

export function useAccountSearch(query: string) {
  const [fetching, setFetching] = useState(false);
  const [hiveContent, setHiveContent] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setFetching(true);
        const _hiveContent = await queryAccountLookup(query);
        setHiveContent(_hiveContent);
        setFetching(false);
      } catch (_) {
        setFetching(false);
      }
    })();
  }, [query]);

  return [fetching, hiveContent] as const;
}

export function useHiveCommunitySearch(query: string) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [hiveContent, setHiveContent] = useState<HiveContent[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const _hiveContent = await fetchCommunities({
          query,
          limit: 3,
          sort: "rank",
          last: "",
          observer: "leofinance"
        });
        if (!areLengthsSame(hiveContent, _hiveContent)) {
          setHiveContent(_hiveContent);
          setFetchState(HiveFetchState.Fetched);
        }
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [query, setFetchState, setHiveContent]);

  return [fetchState, hiveContent] as const;
}

export function useFeaturedTags() {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [hiveContent, setHiveContent] = useState<FeaturedThreadTags>();

  useEffect(() => {
    (async () => {
      try {
        const hiveContent = await leocache.trendingTags;
        setHiveContent(hiveContent);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [setFetchState, setHiveContent]);

  return [fetchState, hiveContent] as const;
}

export function useLeoPolls(
  permlink: string,
  author: string,
  pollVoteState: number
) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [pollContent, setPollContent] = useState();

  useEffect(() => {
    if (fetchState !== HiveFetchState.Initial || !permlink || !author) return;

    void (async function () {
      try {
        const _pollContent = await (
          await fetch(
            `https://cache.inleo.io/poll/leo_poll_${permlink}/${author}`
          )
        ).json();

        setPollContent(_pollContent);
        setFetchState(HiveFetchState.Fetched);
      } catch (_) {
        setFetchState(HiveFetchState.Failed);
      }
    })();
  }, [permlink, author, pollVoteState]);

  return [fetchState, pollContent];
}

interface SaveDraft {
  draft: Draft;
  authorization: Authorization;
  draft_id: string;
}

interface Draft {
  body: string;
  title: string;
  permlink: string;
}

interface Authorization {
  signature: string;
  account: string;
  public_key: string;
}

export function useSaveDraft(
  enabled?: boolean | undefined = true,
  content: string,
  title: string,
  permlink: string,
  currentSlug: string,
  signature: string,
  authorization: any,
  isDarkMode: boolean,
  tags?: Option[],
  selectedReward: string,
  beneficiaries: string[]
) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);
  const [reDraft, setRedraft] = useState(false);
  const [error, setError] = useState(false);
  const { auth, proxy } = getCookie("__session");

  const prepareSettings = {
    beneficiaries: beneficiaries.map(({ tag, ...rest }) => rest),
    tags: tags?.filter(tag => !!tag).map(tag => tag.value)
  };

  let timestamp;
  try {
    timestamp = JSON.parse(atob(auth))?.timestamp;
  } catch {
    if (signature === null && error === false) {
      setError(true);
      toast("Missing signature, please re-login to activate draft saving.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
      setTimeout(() => setError(false), 3000);
    }
  }

  useMemo(() => setInterval(() => setRedraft(cur => !cur), 3000), [setRedraft]);

  const saveDraft = {
    draft: {
      title,
      permlink,
      body: content,
      save_time: new Date().getTime(),
      reward_option: selectedReward,
      tags,
      json_metadata: JSON.stringify({ tags: prepareSettings.tags }),
      public: false,
      extensions: { beneficiaries: prepareSettings.beneficiaries }
    },
    authorization: {
      public_key: authorization.public_key,
      account: authorization.account,
      signature: signature,
      hivesigner: proxy === "hivesigner",
      timestamp: timestamp || 0
    },
    draft_id: currentSlug
  };

  useEffect(() => {
    if (!enabled) return;

    saveDraft.authorization.signature = signature;

    saveDraft.authorization.signature &&
      content &&
      saveInfraDraft(saveDraft)
        .then(() => setFetchState(HiveFetchState.Fetched))
        .catch(() => setFetchState(HiveFetchState.Failed));
  }, [setFetchState, signature, reDraft, enabled]);

  return [fetchState];
}

export function useSaveSchedule(
  authorization,
  signature,
  operations,
  publishTime
) {
  const [fetchState, setFetchState] = useState(HiveFetchState.Initial);

  const saveSchedule = {
    post_content: operations,
    authorization: {
      public_key: authorization.public_key,
      account: authorization.account,
      signature: signature
    },
    publish_time: publishTime
  };

  useEffect(() => {
    saveSchedule.authorization.signature &&
      saveInfraSchedule(saveSchedule)
        .then(() => setFetchState(HiveFetchState.Fetched))
        .catch(() => setFetchState(HiveFetchState.Failed));
  }, [setFetchState]);

  return [fetchState];
}

// Function to calculate the day of the year
export function getDayOfYear(date: any) {
  const startOfYear = new Date(date.getFullYear(), 0, 0);
  const diff = date - startOfYear;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
}

export function useSimpleAnalytics(pageURL: string) {
  const [pageViews, setPageViews] = useState(0);

  /*useEffect(() => {
    void (async function () {
      const now = new Date();
      const minute = now.getMinutes();
      const dayOfYear = getDayOfYear(now);

      const uniqueCode = `${dayOfYear.toString().padStart(3, "0")}${minute
        .toString()
        .padStart(2, "0")}`;
      const result = await (
        await fetch(
          `https://inleo.io/analytics/&fields=pageviews&pages=${pageURL}&info=false&cache_digit=${uniqueCode}`,
          {
            keepalive: true,
            cache: "force-cache"
          }
        )
      ).json();

      setPageViews(result.pageviews);
    })();
  }, [pageURL]);*/

  return { pageViews };
}
