import type { ThreadIndexed } from "~/utils/thread";
import base58 from "bs58";
import type { NaiMap } from "./asset";
import { buildUrl, get } from "./fetchers";
import { cache } from "./cache";
import type { CalculatedManabar, Manabar } from "~/utils/account.type";
import { isSSR } from "./ssr";

export interface AuthorPerm {
  author: string;
  permlink: string;
}

export const SERVER_ADRESS = isSSR() ? "http://135.181.134.210" : "https://cache.inleo.io";

export type HiveContent = Omit<
  any,
  | "id"
  | "active"
  | "last_payout"
  | "net_rshares"
  | "abs_rshares"
  | "vote_rshares"
  | "children_abs_rshares"
  | "max_cashout_time"
  | "total_vote_weight"
  | "reward_weight"
  | "total_payout_value"
  | "curator_payout_value"
  | "author_rewards"
  | "net_votes"
  | "root_comment"
  | "max_accepted_payout"
  | "percent_hbd"
  | "allow_replies"
  | "allow_votes"
  | "allow_curation_rewards"
  | "beneficiaries"
  | "url"
  | "root_title"
  | "pending_payout_value"
  | "total_pending_payout_value"
  | "replies"
  | "promoted"
  | "body_length"
  | "reblogged_by"
  | "first_reblogged_by"
  | "first_reblogged_on"
> & {
  root_author: string;
};
export type PossibleHiveContent = HiveContent | null;

export function stripHiveContent({
  category,
  parent_author,
  author_role,
  parent_permlink,
  author,
  percent_hbd,
  total_payout_value,
  permlink,
  title,
  body,
  json_metadata,
  last_update,
  created,
  depth,
  children,
  cashout_time,
  active_votes,
  author_reputation,
  total_pending_payout_value,
  payout_value,
  pending_payout_value,
  root_author,
  replies,
  beneficiaries,
  root_permlink,
  promoted,
  author_payout_value,
  curator_payout_value,
  max_accepted_payout
}: HiveContent): HiveContent {
  return {
    category,
    parent_author,
    author_role,
    parent_permlink,
    author,
    percent_hbd,
    permlink,
    title,
    beneficiaries,
    body,
    json_metadata,
    last_update,
    created,
    total_payout_value,
    depth,
    children,
    cashout_time,
    active_votes,
    author_reputation,
    total_pending_payout_value,
    payout_value,
    pending_payout_value,
    root_author,
    root_permlink,
    replies,
    promoted,
    author_payout_value,
    curator_payout_value,
    max_accepted_payout
  };
}

export interface AccountMetadata {
  posting_json_metadata: {
    profile?: {
      profile_image?: string;
      name?: string;
      location?: string;
      birthday?: string;
      about?: string;
      version?: number;
      cover_image?: string;
      website?: string;
      type?: string;
      secret?: string;
      redirect_uris?: string[];
      is_public?: boolean;
      creator?: string;
    };
  };
}

export interface ParsedAccount {
  balance: string;
  name: string;
  reputation: number;
  hbd_balance: string;
  proxy: string;
  posting_json_metadata: PostingJsonMetadata;
  json_metadata: JsonMetadata;
  delegated_vesting_shares: string;
  vesting_withdraw_rate: string;
  memo_key: string;
  vesting_shares: string;
  reward_hive_balance: string;
  reward_hbd_balance: string;
  reward_vesting_balance: string;
  created: string; // ISO 8601 date string
  received_vesting_shares: string;
  savings_hbd_balance: string;
  savings_balance: string;
  to_withdraw: number;
  withdrawn: number;
  voting_manabar: Manabar;
  downvote_manabar: Manabar;
  following: number;
  followers: number;
  subscriptions: string[];
  posting: Authority;
  active: Authority;
  premium: Premium;
}

interface PostingJsonMetadata {
  name: string;
  about: string;
  website: string;
  location: string;
  birthday: string;
  profile_image: string;
  profile: Profile;
}

interface Profile {
  name: string;
  about: string;
  website: string;
  location: string;
  birthday: string;
  profile_image: string;
  cover_image: string | null; // Can be null
  version: number;
}

interface JsonMetadata {
  beneficiaries: Beneficiary[];
}

interface Beneficiary {
  name: string;
  weight: number;
  label: string;
}

interface Manabar {
  current_mana: number;
  last_update_time: number;
}

interface Authority {
  account_auths: [string, number][]; // Array of [account name, weight]
  key_auths: [string, number][]; // Array of [key, weight]
  weight_threshold: number;
}

interface Premium {
  is_premium: boolean;
  timestamp: string; // Can be parsed to a Date if needed
  expiration: string; // Can be parsed to a Date if needed
}

export interface ParsedAccounts {
  [accountName: string]: ParsedAccount | undefined;
}

export interface fetchTrendingPostsType {
  tag: string;
  sort: string;
  limit: number;
  start_author?: keyof AuthorPerm["author"] | null;
  start_permlink?: keyof AuthorPerm["permlink"] | null;
  observer: string | null; // Name
}

export function fetchHiveTrendingPosts(params: fetchTrendingPostsType): Promise<HiveContent[]> {
  return callHiveClient("bridge", "get_ranked_posts", params);
}

export function fetchContent(authorPerm: AuthorPerm): Promise<HiveContent> {
  return callHiveClient("condenser_api", "get_content", [authorPerm.author, authorPerm.permlink]);
}

export function fetchPosts(
  author: string,
  limit: number,
  start_author: string | null,
  start_permlink: string | null
): Promise<HiveContent[]> {
  return callHiveClient("bridge", "get_account_posts", {
    account: author,
    limit,
    observer: "leofinance",
    sort: "posts",
    start_author,
    start_permlink
  });
}

interface FetchFollowingPosts {
  author: string;
  limit: number;
  sort: "feed" | "posts";
  start_author: string | null;
  start_permlink: string | null;
}

export function fetchFollowingPosts({
  author,
  limit,
  sort,
  start_author,
  start_permlink
}: FetchFollowingPosts): Promise<HiveContent[]> {
  return callHiveClient("bridge", "get_account_posts", {
    account: author,
    observer: author,
    limit,
    sort,
    start_author,
    start_permlink
  });
}

export function fetchReplies(authorPerm: AuthorPerm): Promise<HiveContent[]> {
  return callHiveClient("condenser_api", "get_content_replies", [authorPerm.author, authorPerm.permlink]);
}

export function fetchDiscussion(authorPerm: AuthorPerm): Promise<HiveContent[]> {
  return callHiveClient("bridge", "get_discussion", [authorPerm.author, authorPerm.permlink, "noleo4u"]);
}

export async function fetchAccount(accountName: string, renew?: boolean): Promise<any | undefined> {
  //const accounts_array = await callHiveClient("condenser_api", "get_accounts", [
  //  [accountName]
  //]);
  //return accounts_array?.[0];
  if (!accountName) {
    return {};
  }

  try {
    if (renew === true) {
      const response = await fetch(`${SERVER_ADRESS}/account/renew/${accountName}`, {
        headers: {
          Cache: "no-store"
        }
      });
      const account = await response.json();

      return account;
    } else {
      const response = await fetch(`${SERVER_ADRESS}/account`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          accounts: [accountName]
        })
      });
      const account = await response.json();

      return account[0];
    }
  } catch {
    try {
      const response = await fetch(`${SERVER_ADRESS}/account/${accountName}`);
      const account = await response.json();

      return account;
    } catch {
      const accounts_array = await callHiveClient("condenser_api", "get_accounts", [[accountName]]);
      return accounts_array?.[0];
    }
  }
}

export async function fetchAccounts(accountNames: string[]): Promise<any[]> {
  let accounts;

  if (accountNames.length === 1) {
    try {
      let account: string = accountNames[0];

      const response = await fetch(`${SERVER_ADRESS}/account/${account}`);

      accounts = [await response.json()];
    } catch {
      accounts = [];
    }
  } else {
    try {
      const response = await fetch(`${SERVER_ADRESS}/account`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          accounts: accountNames
        })
      });

      accounts = await response.json();
    } catch {
      accounts = [];
    }
  }

  return accounts;
}

export interface FollowCount {
  account: string;
  follower_count: number;
  following_count: number;
}

export async function fetchFollowCount(accountName: string): Promise<FollowCount> {
  await new Promise(r => setTimeout(r, 20));
  return callHiveClient("condenser_api", "get_follow_count", [accountName]);
}

export function fetchFollowCounts(accountNames: string[]): Promise<FollowCount[]> {
  return Promise.all(accountNames.map(fetchFollowCount));
}

export async function fetchRecurrentOperations(accountName: string) {
  return callHiveClient("database_api", "find_recurrent_transfers", {
    from: accountName
  });
}

const REPUTATION_DEFAULT = 25;

export function calculateReputation(reputation: HiveContent["author_reputation"]) {
  const rep = Math.log10(Math.abs(reputation)) - 9;
  const calculatedReputation = Math.max(rep, 0) * Math.sign(reputation) * 9 + REPUTATION_DEFAULT;

  return calculatedReputation > 0 ? calculatedReputation : REPUTATION_DEFAULT;
}

export function getAvatar(accountName: HiveContent["author"], size: string) {
  return `https://img.inleo.io/u/${accountName}/avatar/${
    size === "large" ? "large" : size === "medium" ? "medium" : "small"
  }`;
}

export function authorPermToString({ author, permlink }: AuthorPerm) {
  if (!author || !permlink) return "";
  return `${author}/${permlink}`;
}

export function stringToAuthorPerm(authorPermString: string): AuthorPerm {
  const [author, permlink] = authorPermString.split("/");
  return { author, permlink };
}

export function contentToAuthorPerm({ permlink, author }: HiveContent): AuthorPerm {
  return stringToAuthorPerm(`${author}/${permlink}`);
}

export interface UnreadNotifications {
  lastread: string;
  unread: number;
}

export function createEmptyUnreadNotification(): UnreadNotifications {
  return { unread: 0, lastread: "" };
}

export function calculateRCMana(rc_account: any): any {
  return calculateManabar(Number(rc_account.max_rc), rc_account.rc_manabar);
}

export function calculateVPMana(account: any): any {
  const max_mana: number = account?.vesting_shares?.split(" VESTS")[0] * Math.pow(10, 6);
  return calculateManabar(max_mana, account.voting_manabar);
}

// export function calculateManabar(
//   max_mana: number,
//   { current_mana, last_update_time }
// ): Manabar {
//   console.log('Last updated time', new Date(last_update_time * 1000))
//   const delta: number = (Date.now() / 1000) - last_update_time;
//   current_mana = (Number(current_mana) + (delta * max_mana) )/ 432000; // 432000 means 5 days
//   let percentage: number = Math.round((current_mana / max_mana) * 10000);
//
//   if (!isFinite(percentage) || percentage < 0) {
//     percentage = 0.5;
//   } else if (percentage > 10000) {
//     percentage = 10000;
//   }
//
//   return { current_mana, max_mana, percentage };
// }

export function calculateManabar(max_mana: number, { current_mana, last_update_time }: Manabar): CalculatedManabar {
  const delta: number = Date.now() / 1000 - last_update_time;
  current_mana = Number(current_mana) + (delta * max_mana) / 432000;
  let percentage: number = Math.round((current_mana / max_mana) * 10000);

  if (!isFinite(percentage) || percentage < 0) {
    percentage = 0;
  } else if (percentage > 10000) {
    percentage = 10000;
  }
  return { current_mana, max_mana, percentage };
}

type HiveApi =
  | "account_by_key"
  | "block_api"
  | "condenser_api"
  | "bridge"
  | "database_api"
  | "market_history_api"
  | "network_broadcast_api"
  | "node_status_api"
  | "rc_api"
  | "transaction_status_api"
  | "wallet_bridge_api";

type BridgeApiParams =
  | "account_notifications"
  | "does_user_follow_any_lists"
  | "get_account_posts"
  | "get_community"
  | "unread_notifications"
  | "get_community_context"
  | "get_discussion"
  | "get_follow_list"
  | "get_payout_stats"
  | "get_post"
  | "get_post_header"
  | "get_profile"
  | "get_ranked_posts"
  | "get_relationship_between_accounts"
  | "list_all_subscriptions"
  | "list_communities"
  | "list_community_roles"
  | "list_pop_communities"
  | "list_subscribers";

type DatabaseApiParams =
  | "find_account_recovery_requests"
  | "find_accounts"
  | "find_change_recovery_account_requests"
  | "find_collateralized_conversion_requests"
  | "find_comments"
  | "find_decline_voting_rights_requests"
  | "find_escrows"
  | "find_hbd_conversion_requests"
  | "find_limit_orders"
  | "find_owner_histories"
  | "find_proposals"
  | "find_recurrent_transfers"
  | "find_savings_withdrawals"
  | "find_vesting_delegation_expirations"
  | "find_vesting_delegations"
  | "find_votes"
  | "find_withdraw_vesting_routes"
  | "find_witnesses"
  | "get_active_witnesses"
  | "get_comment_pending_payouts"
  | "get_config"
  | "get_current_price_feed"
  | "get_dynamic_global_properties"
  | "get_feed_history"
  | "get_hardfork_properties"
  | "get_order_book"
  | "get_potential_signatures"
  | "get_required_signatures"
  | "get_reward_funds"
  | "get_transaction_hex"
  | "get_version"
  | "get_witness_schedule"
  | "is_known_transaction"
  | "list_account_recovery_requests"
  | "list_accounts"
  | "list_change_recovery_account_requests"
  | "list_collateralized_conversion_requests"
  | "list_decline_voting_rights_requests"
  | "list_escrows"
  | "list_hbd_conversion_requests"
  | "list_limit_orders"
  | "list_owner_histories"
  | "list_proposal_votes"
  | "list_proposals"
  | "list_savings_withdrawals"
  | "list_vesting_delegation_expirations"
  | "list_vesting_delegations"
  | "list_votes"
  | "list_withdraw_vesting_routes"
  | "list_witness_votes"
  | "list_witnesses"
  | "verify_account_authority"
  | "verify_authority"
  | "verify_signatures";

type CondenserApiParams =
  | "broadcast_transaction"
  | "broadcast_transaction_synchronous"
  | "find_proposals"
  | "find_rc_accounts"
  | "find_recurrent_transfers"
  | "get_account_count"
  | "get_account_history"
  | "get_account_references"
  | "get_account_reputations"
  | "get_account_votes"
  | "get_accounts"
  | "get_active_votes"
  | "get_active_witnesses"
  | "get_block"
  | "get_block_header"
  | "get_blog"
  | "get_blog_authors"
  | "get_blog_entries"
  | "get_chain_properties"
  | "get_collateralized_conversion_requests"
  | "get_comment_discussions_by_payout"
  | "get_config"
  | "get_content"
  | "get_content_replies"
  | "get_conversion_requests"
  | "get_current_median_history_price"
  | "get_discussions_by_active"
  | "get_discussions_by_author_before_date"
  | "get_discussions_by_blog"
  | "get_discussions_by_cashout"
  | "get_discussions_by_children"
  | "get_discussions_by_comments"
  | "get_discussions_by_created"
  | "get_discussions_by_feed"
  | "get_discussions_by_hot"
  | "get_discussions_by_promoted"
  | "get_discussions_by_trending"
  | "get_discussions_by_votes"
  | "get_dynamic_global_properties"
  | "get_escrow"
  | "get_expiring_vesting_delegations"
  | "get_feed"
  | "get_feed_entries"
  | "get_feed_history"
  | "get_follow_count"
  | "get_followers"
  | "get_following"
  | "get_hardfork_version"
  | "get_key_references"
  | "get_market_history"
  | "get_market_history_buckets"
  | "get_next_scheduled_hardfork"
  | "get_open_orders"
  | "get_ops_in_block"
  | "get_order_book"
  | "get_owner_history"
  | "get_post_discussions_by_payout"
  | "get_potential_signatures"
  | "get_reblogged_by"
  | "get_recent_trades"
  | "get_recovery_request"
  | "get_replies_by_last_update"
  | "get_required_signatures"
  | "get_reward_fund"
  | "get_savings_withdraw_from"
  | "get_savings_withdraw_to"
  | "get_state"
  | "get_tags_used_by_author"
  | "get_ticker"
  | "get_trade_history"
  | "get_transaction"
  | "get_transaction_hex"
  | "get_trending_tags"
  | "get_version"
  | "get_vesting_delegations"
  | "get_volume"
  | "get_withdraw_routes"
  | "get_witness_by_account"
  | "get_witness_count"
  | "get_witness_schedule"
  | "get_witnesses"
  | "get_witnesses_by_vote"
  | "is_known_transaction"
  | "list_proposal_votes"
  | "list_proposals"
  | "list_rc_accounts"
  | "list_rc_direct_delegations"
  | "lookup_account_names"
  | "lookup_accounts"
  | "lookup_witness_accounts"
  | "verify_account_authority"
  | "verify_authority";

type Params = {
  database_api: DatabaseApiParams;
  bridge: BridgeApiParams;
  condenser_api: CondenserApiParams;
};

const reliableNodesList = [
  "https://hive.inleo.io",
  "https://api.deathwing.me",
  "https://api.hive.blog",
  "https://beacon.peakd.com/"
];

export async function callHiveBlog<T extends HiveApi>(
  //clientList: string[],
  api: T,
  method: Params[T],
  params?: object
) {
  const prepared = {
    jsonrpc: "2.0",
    method: `${api}.${method}`,
    params,
    id: 0
  };

  const response = await fetch("https://api.hive.blog", {
    method: "POST",
    body: JSON.stringify(prepared)
  });
  if (!response.ok) {
    console.error(`Error fetching ${"https://api.hive.blog"}: ${response.statusText}`);
  }
  return (await response.json()).result;
}

export async function callHiveBatched<T extends HiveApi>(
  requests: {
    api: T;
    method: Params[T];
    params?: object;
  }[]
) {
  const preparedRequests = requests.map(request => {
    return {
      jsonrpc: "2.0",
      method: `${request.api}.${request.method}`,
      params: request.params,
      id: 0
    };
  });

  const responses = await fetch(reliableNodesList[0], {
    method: "POST",
    body: JSON.stringify(preparedRequests)
  }).then(res => res.json());

  responses.map(response => response.result);

  return responses;
}

export async function callHiveClient<T extends HiveApi>(
  api: T,
  method: Params[T],
  params?: object,
  alternativeNode?: string
) {
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const prepared = {
    jsonrpc: "2.0",
    method: `${api}.${method}`,
    params,
    id: 0
  };

  // Ensure reliableNodesList is properly populated
  if (!Array.isArray(reliableNodesList) || reliableNodesList.length === 0) {
    console.error("reliableNodesList is empty or not properly defined.");
    return null;
  }

  const nodesToTry = alternativeNode ? [alternativeNode] : reliableNodesList;
  const maxRetries = 3; // Limit total retries to 3
  let retries = 0;
  let nodeIndex = 0;

  while (retries < maxRetries) {
    const node = nodesToTry[nodeIndex % nodesToTry.length]; // Cycle through nodes
    try {
      const response = await fetch(node, {
        method: "POST",
        body: JSON.stringify(prepared)
      });

      if (!response.ok) {
        console.error(`Error fetching ${node}: ${response.statusText}`);
        await delay(100);
      } else {
        const result = await response.json();
        return result.result;
      }
    } catch (error) {
      console.error({
        request: prepared,
        method: `${api}.${method}`,
        error: `Error fetching ${node}: ${error instanceof Error ? error.message : error}`
      });
      await delay(100);
    }
    retries++;
    nodeIndex++;
  }

  console.error(`All fetch attempts failed after ${maxRetries} retries.`);
  return null;
}

export function fetchUnreadNotifications(accountName: string): Promise<UnreadNotifications> {
  return callHiveClient("bridge", "unread_notifications", {
    account: accountName
  });
}

// https://gitlab.syncad.com/hive/hivemind/-/blob/master/hive/server/hive_api/notify.py
export enum NotificationType {
  // Community
  NewCommunity = "new_community",
  SetRole = "set_role",
  SetProps = "set_props",
  SetLabel = "set_label",
  MutePost = "mute_post",
  UnmutePost = "unmute_post",
  PinPost = "pin_post",
  UnpinPost = "unpin_post",
  FlagPost = "flag_post",
  Subscribe = "subscribe",

  // Personal
  Error = "error",
  Reblog = "reblog",
  Follow = "follow",
  Reply = "reply",
  ReplyComment = "reply_comment",
  Mention = "mention",
  Vote = "vote"
}

export enum ContentNotificationType {
  Reblog = "reblog",
  Reply = "reply",
  ReplyComment = "reply_comment",
  Mention = "mention",
  Vote = "vote"
}

export enum ThreadNotificationType {
  ReplyComment = "reply_comment",
  Mention = "mention",
  Vote = "vote"
}

export interface Notification {
  length: number;
  date: string;
  id: number;
  msg: string;
  score: number;
  type: NotificationType;
  url: string;
}

export interface NotificationContent {
  content: HiveContent;
  thread: ThreadIndexed | null;
  parentContent: HiveContent | null;
  parentThread: ThreadIndexed | null;
}

export interface NotificationToParse extends Notification {
  content: NotificationContent | null;
}

export interface ParsedGenericNotification extends Notification {
  content: null;
}

export interface ParsedHiveNotification extends Omit<Notification, "type"> {
  content: {
    content: HiveContent;
    thread: null;
    parentContent: HiveContent | null;
    parentThread: ThreadIndexed | null;
  };
  type: ContentNotificationType;
}

export interface ParsedThreadNotification extends Omit<Notification, "type"> {
  content: {
    content: HiveContent;
    thread: ThreadIndexed;
    parentContent: HiveContent | null;
    parentThread: ThreadIndexed | null;
  };
  type: ThreadNotificationType;
}

export type ParsedContentNotification = ParsedHiveNotification | ParsedThreadNotification;
export type ParsedNotification = ParsedGenericNotification | ParsedContentNotification;

export function fetchNotifications(accountName: string, limit: number, lastId?: number): Promise<Notification[]> {
  return callHiveClient("bridge", "account_notifications", {
    account: accountName,
    limit,
    last_id: lastId
  });
}

export function fetchAccountSubscriptions(accountName: string): Promise<Array<any>> {
  return callHiveClient("bridge", "list_all_subscriptions", {
    account: accountName
  });
}

// TODO: Extract this into env or smth
export const THREADS_AUTHOR = "leothreads";
export const THREADS_APP = "leothreads/0.3";

export interface PostHeader extends AuthorPerm {
  category: string;
  depth: number;
}

export function fetchPostHeader(authorPerm: AuthorPerm): Promise<PostHeader> {
  return callHiveBlog("bridge", "get_post_header", {
    author: authorPerm.author,
    permlink: authorPerm.permlink
  });
}

export function addNoiseToPermlink(authorPerm: AuthorPerm): string {
  const noise = base58.encode(crypto.getRandomValues(new Uint8Array(6))).toLowerCase();

  const permlink = `${authorPerm.permlink}-${noise}`;

  // try {
  //   await fetchPostHeader({ author: authorPerm.author, permlink });
  // } catch (_) {
  //   return permlink;
  // }

  return permlink;
}

export async function addNoiseToPermlinkPost(authorPerm: AuthorPerm): Promise<string> {
  const noise = base58.encode(crypto.getRandomValues(new Uint8Array(2))).toLowerCase();
  const permlink = `${authorPerm.permlink}-${noise}`;

  try {
    await fetchPostHeader({
      author: authorPerm.author,
      permlink: authorPerm.permlink
    });

    return permlink;
  } catch (_) {
    return authorPerm?.permlink;
  }
}

export function fetchAccountPosts(accountName: string, limit: number): Promise<HiveContent[]> {
  return callHiveClient("condenser_api", "get_blog_entries", [accountName, 0, limit]);
}

export function queryAccountLookup(query: string) {
  return callHiveClient("condenser_api", "lookup_accounts", [query, 7]);
}

export function fetchAccountFollowers(accountName: string, start: number = 0) {
  return callHiveClient("condenser_api", "get_followers", [accountName, start, "blog", 200]);
}

export function fetchAccountIgnores(accountName: string) {
  return callHiveClient("condenser_api", "get_following", [accountName, 0, "ignore", 1000]);
}

export function getDynamicGlobalProperties(): Promise<any> {
  return callHiveClient("condenser_api", "get_dynamic_global_properties", []);
}

export function getFeedHistory(): Promise<any> {
  return callHiveClient("condenser_api", "get_feed_history", []);
}

export function fetchAccountHistory(account: string, start: string, limit: string) {
  return callHiveClient("condenser_api", "get_account_history", [
    account,
    start,
    limit,
    "14113755137343234172",
    "655365"
  ]);
}

interface fetchCommunitiesParams {
  limit: number;
  sort: string;
  query: string | null;
  observer: string;
  last: string;
}

export function fetchCommunities(params?: fetchCommunitiesParams): Promise<any> {
  return callHiveClient("bridge", "list_communities", {
    ...params
  });
}

interface fetchCommunityContextParams {
  name: string;
  observer: string;
}

export interface fetchCommunityContextResult {
  id: number;
  name: string;
  title: string;
  about: string;
  lang: string;
  type_id: number;
  is_nsfw: boolean;
  subscribers: number;
  created_at: string;
  sum_pending: number;
  num_pending: number;
  num_authors: number;
  avatar_url: string;
  description: string;
  flag_text: string;
  settings: any;
  context: any;
  team: string[][];
}

export function fetchCommunityContext(name: string): Promise<fetchCommunityContextResult> {
  return callHiveClient("bridge", "get_community", {
    name
  });
}

export interface TrendingTag {
  comments: number;
  name: string;
  top_posts: number;
  total_payouts: string;
}

export function fetchTrendingTags(limit: number): Promise<TrendingTag[]> {
  return callHiveClient("condenser_api", "get_trending_tags", ["", limit]);
}

export function fetchCommunityMembers(community: string): Promise<[string, string, any, string]> {
  return callHiveClient("bridge", "list_subscribers", {
    community
  });
}

export function isCommunity(s: string) {
  return s.match(/^hive-\d+/) !== null;
}

export interface Delegation {
  delegatee: string;
  delegator: string;
  id: number;
  min_delegation_time: string;
  vesting_shares: {
    amount: string;
    nai: NaiMap;
    precision: number;
  };
}

export interface AccountVesting {
  delegations: Delegation[];
}

export function findVestingDelegations(account: string): Promise<AccountVesting> {
  return callHiveClient("database_api", "find_vesting_delegations", {
    account
  });
}

export const getTribeAccountInfo = async (accountName: string) => {
  const data = await get(buildUrl("https://scot-api.hive-engine.com", `@${accountName}?hive=1`));

  return data["LEO"];
};

export const getTribeConfig = () => {
  return {
    allowlist_account: null,
    author_curve_exponent: 1.0,
    author_reward_percentage: 50.0,
    badge_fee: -1.0,
    beneficiaries_account: "h@leoburn",
    beneficiaries_reward_percentage: 20.0,
    cashout_window_days: 7.0,
    curation_curve_exponent: 1.0,
    disable_downvoting: false,
    downvote_power_consumption: 2000,
    downvote_regeneration_seconds: 432000,
    downvote_window_days: -1,
    enable_account_allowlist: null,
    enable_account_muting: true,
    enable_comment_beneficiaries: true,
    exclude_apps: null,
    exclude_apps_from_token_beneficiary: "leofinance",
    exclude_beneficiaries_accounts: "likwid,finex,sct.krwp,h@likwid,h@finex,h@sct.krwp,rewarding.app,h@rewarding.app",
    exclude_tags: "actifit,steemhunt,appics,dlike,share2steem,dbuzz",
    fee_post_account: null,
    fee_post_amount: 0,
    fee_post_exempt_beneficiary_account: null,
    fee_post_exempt_beneficiary_weight: 0,
    hive_community: "hive-167922",
    hive_enabled: true,
    hive_engine_enabled: true,
    issue_token: true,
    json_metadata_app_value: null,
    json_metadata_key: "tags",
    json_metadata_value: "leofinance,leo",
    max_auto_claim_amount: 5472.0,
    miner_tokens: '{"LEOM": 1, "LEOMM": 4}',
    mining_pool_claim_number: 30,
    mining_pool_claims_per_year: 8760,
    muting_account: "noleo4u",
    n_daily_posts_muted_accounts: 0,
    other_pool_accounts: '{"wleo.pool": 15}',
    other_pool_percentage: 15.0,
    other_pool_send_token_per_year: 365,
    pob_comment_pool_percentage: -1.0,
    pob_pool_percentage: 70.0,
    posm_pool_percentage: 15.0,
    post_reward_curve: "default",
    post_reward_curve_parameter: null,
    promoted_post_account: "null",
    reduction_every_n_block: 10512000,
    reduction_percentage: 7.0,
    rewards_token: 19.0,
    rewards_token_every_n_block: 100,
    staked_reward_percentage: 0.0,
    staking_pool_claim_number: 0,
    staking_pool_claims_per_year: 0,
    staking_pool_percentage: 0.0,
    steem_enabled: false,
    steem_engine_enabled: false,
    tag_adding_window_hours: 1.0,
    token: "LEO",
    token_account: "leo.tokens",
    use_staking_circulating_quotent: false,
    vote_power_consumption: 200,
    vote_regeneration_seconds: 432000,
    vote_window_days: -1
  };
};

export interface HiveEngineTribe {
  allowlist_account?: null;
  author_curve_exponent: number;
  author_reward_percentage: number;
  badge_fee: number;
  beneficiaries_account: string;
  beneficiaries_reward_percentage: number;
  cashout_window_days: number;
  curation_curve_exponent: number;
  disable_downvoting: boolean;
  downvote_power_consumption: number;
  downvote_regeneration_seconds: number;
  downvote_window_days: number;
  enable_account_allowlist?: null;
  enable_account_muting: boolean;
  enable_comment_beneficiaries: boolean;
  exclude_apps?: null;
  exclude_apps_from_token_beneficiary: string;
  exclude_beneficiaries_accounts: string;
  exclude_tags: string;
  fee_post_account?: null;
  fee_post_amount: number;
  fee_post_exempt_beneficiary_account?: null;
  fee_post_exempt_beneficiary_weight: number;
  hive_community: string;
  hive_enabled: boolean;
  hive_engine_enabled: boolean;
  issue_token: boolean;
  json_metadata_app_value?: null;
  json_metadata_key: string;
  json_metadata_value: string;
  max_auto_claim_amount: number;
  miner_tokens: string;
  mining_pool_claim_number: number;
  mining_pool_claims_per_year: number;
  muting_account: string;
  n_daily_posts_muted_accounts: number;
  other_pool_accounts: string;
  other_pool_percentage: number;
  other_pool_send_token_per_year: number;
  pob_comment_pool_percentage: number;
  pob_pool_percentage: number;
  posm_pool_percentage: number;
  post_reward_curve: string;
  post_reward_curve_parameter?: null;
  promoted_post_account: string;
  reduction_every_n_block: number;
  reduction_percentage: number;
  rewards_token: number;
  rewards_token_every_n_block: number;
  staked_reward_percentage: number;
  staking_pool_claim_number: number;
  staking_pool_claims_per_year: number;
  staking_pool_percentage: number;
  steem_enabled: boolean;
  steem_engine_enabled: boolean;
  tag_adding_window_hours: number;
  token: string;
  token_account: string;
  use_staking_circulating_quotent: boolean;
  vote_power_consumption: number;
  vote_regeneration_seconds: number;
  vote_window_days: number;
}

export const LEO_TOKEN_CONFIG = {
  allowlist_account: null,
  author_curve_exponent: 1.0,
  author_reward_percentage: 50.0,
  badge_fee: -1.0,
  beneficiaries_account: "h@leoburn",
  beneficiaries_reward_percentage: 20.0,
  cashout_window_days: 7.0,
  curation_curve_exponent: 1.0,
  disable_downvoting: false,
  downvote_power_consumption: 2000,
  downvote_regeneration_seconds: 432000,
  downvote_window_days: -1,
  enable_account_allowlist: null,
  enable_account_muting: true,
  enable_comment_beneficiaries: true,
  exclude_apps: null,
  exclude_apps_from_token_beneficiary: "leofinance",
  exclude_beneficiaries_accounts: "likwid,finex,sct.krwp,h@likwid,h@finex,h@sct.krwp,rewarding.app,h@rewarding.app",
  exclude_tags: "actifit,steemhunt,appics,dlike,share2steem,dbuzz",
  fee_post_account: null,
  fee_post_amount: 0,
  fee_post_exempt_beneficiary_account: null,
  fee_post_exempt_beneficiary_weight: 0,
  hive_community: "hive-167922",
  hive_enabled: true,
  hive_engine_enabled: true,
  issue_token: true,
  json_metadata_app_value: null,
  json_metadata_key: "tags",
  json_metadata_value: "leofinance,leo",
  max_auto_claim_amount: 5472.0,
  miner_tokens: '{"LEOM": 1, "LEOMM": 4}',
  mining_pool_claim_number: 30,
  mining_pool_claims_per_year: 8760,
  muting_account: "noleo4u",
  n_daily_posts_muted_accounts: 0,
  other_pool_accounts: '{"wleo.pool": 15}',
  other_pool_percentage: 15.0,
  other_pool_send_token_per_year: 365,
  pob_comment_pool_percentage: -1.0,
  pob_pool_percentage: 70.0,
  posm_pool_percentage: 15.0,
  post_reward_curve: "default",
  post_reward_curve_parameter: null,
  promoted_post_account: "null",
  reduction_every_n_block: 10512000,
  reduction_percentage: 7.0,
  rewards_token: 19.0,
  rewards_token_every_n_block: 100,
  staked_reward_percentage: 0.0,
  staking_pool_claim_number: 0,
  staking_pool_claims_per_year: 0,
  staking_pool_percentage: 0.0,
  steem_enabled: false,
  steem_engine_enabled: false,
  tag_adding_window_hours: 1.0,
  token: "LEO",
  token_account: "leo.tokens",
  use_staking_circulating_quotent: false,
  vote_power_consumption: 200,
  vote_regeneration_seconds: 432000,
  vote_window_days: -1
};

const loadHiveClient = async (): Promise<import("@hiveio/dhive").Client> => {
  try {
    const dhive = await import("@hiveio/dhive");
    return new dhive.default.Client(reliableNodesList, {
      timeout: 3000,
      failoverThreshold: 3,
      consoleOnFailover: true
    });
  } catch (error) {
    console.error("Failed to load @hiveio/dhive Client:", error);
    throw error;
  }
};

export const loadHivePrivateKey = async (): Promise<import("@hiveio/dhive").Client> => {
  try {
    const dhive = await import("@hiveio/dhive");
    return dhive.default.PrivateKey;
  } catch (error) {
    console.error("Failed to load @hiveio/dhive Client:", error);
    throw error;
  }
};

/**
 * Calculates the Voting Power (VP) of a given account.
 *
 * @param {any} account - The account object compatible with dhive's ExtendedAccount.
 * @returns {Promise<number>} The calculated Voting Power.
 */
export const calcVotingPower = async (account: any): Promise<number> => {
  try {
    const client = await loadHiveClient();

    // Ensure that 'client.rc.calculateVPMana' exists and is a function
    if (client.rc && typeof client.rc.calculateVPMana === "function") {
      const calc = account ? client.rc.calculateVPMana(account) : 0;
      return calc;
    } else {
      console.error("calculateVPMana function is not available on client.rc");
      return 0;
    }
  } catch (error) {
    console.error("Error calculating voting power:", error);
    return 0;
  }
};
export const tribeVotingPower = (account: any, config: HiveEngineTribe) => {
  return account
    ? Math.min(
        account.voting_power +
          ((new Date() - new Date(account.last_vote_time + "Z")) * 10000) / (1000 * config.vote_regeneration_seconds),
        10000
      ) / 100
    : 0;
};

const buildRewardData = (post, tribeInfo) => {
  return {
    author_curve_exponent: post.author_curve_exponent,
    reward_pool: tribeInfo.reward_pool || 0,
    pending_rshares: tribeInfo.pending_rshares || 0
  };
};

const applyRewardsCurve = (r, rewardData) =>
  (Math.max(0, r) ** rewardData.author_curve_exponent * rewardData.reward_pool) / rewardData.pending_rshares;

export const estimateMyVoteValue = async (voteEntries, account, weight, config, info, tokenPrices) => {
  const { name, staked_tokens: staked, vote_weight_multiplier: upMul, downvote_weight_multiplier: downMul } = account;
  const { precision } = info;
  const sign = Math.sign(weight);

  const multiplier = sign !== -1 ? upMul : downMul || 1;

  const rsharesTotal = voteEntries.reduce((acc, [_, rshares]) => acc + rshares, 0);

  const correctedWeight = Math.min(multiplier * weight, 10000);
  const power = tribeVotingPower(account, config);
  const rshares = sign * ((staked * correctedWeight * power) / (10000 * 100));

  const rewardData = buildRewardData(config, info);
  const pendingPrev = applyRewardsCurve(rsharesTotal, rewardData) / 10;
  const pendingNext = applyRewardsCurve(rsharesTotal + rshares, rewardData) / 10;

  const pendingCorrected = pendingNext - pendingPrev;
  const estimatedLeo = pendingCorrected.toFixed(precision);

  const { reward_balance, recent_claims } = await cache.getRewardFund();
  const accountData = await cache.getAccount(name);
  const { vesting_shares, received_vesting_shares, delegated_vesting_shares, voting_power } = accountData;

  const total_vests =
    +vesting_shares?.replaceAll(" VESTS", "") +
    (+received_vesting_shares?.replaceAll(" VESTS", "") || 0) -
    +delegated_vesting_shares?.replaceAll(" VESTS", "");
  const final_vest = total_vests * 1e6;
  ("");
  const hive_voting_power = calculateVPMana(accountData).percentage / 100;
  const hive_rshares = (hive_voting_power * final_vest) / 10000;

  const estimatedHive = (hive_rshares / +recent_claims) * +reward_balance.replaceAll(" HIVE", "");

  return {
    leo: +estimatedLeo,
    hive: +estimatedHive,
    leo_usd: estimatedLeo * tokenPrices["wrapped-leo"]?.usd,
    hive_usd: estimatedHive * tokenPrices["hive_dollar"]?.usd,
    sum: estimatedLeo * tokenPrices["wrapped-leo"]?.usd + estimatedHive * tokenPrices["hive_dollar"]?.usd
  };
};

export interface HiveAccountKeys {
  public: {
    posting: string;
    active: string;
    ownerKey: string;
    memo: string;
  };
  private: {
    ownerKey: string;
    activeKey: string;
    postingKey: string;
  };
  memo: string;
  password: string;
}

export const generateFileFromText = (generatedKeys: any, username: string) => {
  return `
    Welcome the the world of InLeo!\n
    Never share your private keys with anyone and never lose your private keys, there's no way to access them again. \n
    Keys of account ${username}.
    Active Private Key: ${generatedKeys.private.activeKey}
    Active Public Key: ${generatedKeys.public.active}\n
    Posting Private Key: ${generatedKeys.private.postingKey}
    Posting Public Key: ${generatedKeys.public.posting}\n
    Owner Private Key: ${generatedKeys.private.ownerKey}
    Owner Public Key: ${generatedKeys.public.ownerKey}\n
    Memo Key: ${generatedKeys.memo}\n
    Master Password: ${generatedKeys.password}
  `;
};
